import React, { FC, useState, useCallback, ComponentPropsWithRef } from 'react'
import { Transition } from '@headlessui/react'
import useInterval from '@hooks/useInterval'
import Image from 'next/image'
import c from 'clsx'

import tire from 'public/graphics/main/carousel/tire.png'
import forklift from 'public/graphics/main/carousel/forklift.png'
import mri from 'public/graphics/main/carousel/mri.png'
import printer from 'public/graphics/main/carousel/printer.png'
import coffee from 'public/graphics/main/carousel/coffee.png'
import treadmill from 'public/graphics/main/carousel/treadmill.png'

const slides = [
    {
        alt: 'Оборудование для ресторанов',
        image: coffee
    },
    {
        alt: 'Оборудование для шиномонтажа',
        image: tire
    },
    {
        alt: 'Медицнское оборудование',
        image: mri
    },
    {
        alt: 'Оборудование для фитнеса',
        image: treadmill
    },
    {
        alt: 'Оборудование для офиса',
        image: printer
    },
    {
        alt: 'Торгово-складское оборудование',
        image: forklift
    }
]

function getBrowserDocumentHiddenProp() {
    if (typeof document.hidden !== 'undefined') {
        return 'hidden'
        //@ts-expect-error
    } else if (typeof document.msHidden !== 'undefined') {
        return 'msHidden'
        //@ts-expect-error
    } else if (typeof document.webkitHidden !== 'undefined') {
        return 'webkitHidden'
    }
    return ''
}

function getIsDocumentVisible() {
    //@ts-expect-error
    return !document[getBrowserDocumentHiddenProp()]
}

const Carousel: FC<ComponentPropsWithRef<'div'>> = (props) => {
    const [slide, setSlide] = useState(0)
    const [loaded, setLoaded] = useState(0)
    const changeSlide = useCallback(() => {
        if (!getIsDocumentVisible()) return
        setSlide(s => loaded ? (s + 1) % loaded : 0)
    }, [loaded])
    useInterval(changeSlide, 3000)

    return (
        <div className='w-80 h-80 lg:w-140 lg:h-140 relative text-opacity-0' {...props}>

            {slides.map((s, i) => (
                <Transition
                    key={s.alt}
                    appear={true}
                    className='inset-0 absolute w-full h-full origin-center transition-all duration-500'
                    enterFrom='-translate-y-1/4 translate-x-1/3 opacity-0'
                    enterTo='opacity-100 scale-100 delay-300'
                    leaveTo='translate-y-1/4 -translate-x-1/3 opacity-0'
                    show={i < loaded && i === slide}
                    unmount={false}
                >
                    <div className='relative w-full h-full'>
                        <Image
                            priority={i === loaded}
                            src={s.image}
                            alt={s.alt}
                            layout='fill'
                            quality={95}
                            objectFit='cover'
                            onLoadingComplete={() => {
                                if (loaded === i) {
                                    setLoaded(i + 1)
                                }
                            }}
                        />
                    </div>
                </Transition>
            ))}
        </div>
    )
}

export default Carousel